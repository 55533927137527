import React from "react"
import ArticleContentBody from "../../components/pages/ArticleDetails/ArticleContentBody"
import Layout from "../../components/layout"
import Seo from "../../components/seo"

const SettingUpTaxProfile = () => {
  return (
    <Layout>
      <Seo
        title="How to Set Up Your Tax Profile in Synkli Mobile App"
        description={`Learn how to set up and update your tax profile using Synkli Mobile App. Follow the steps to log in, access settings, and edit your financial details.`}
      />

      {/* This slug is matched with articles in Content */}
      <ArticleContentBody slug={`setting-up-tax-profile`} />
    </Layout>
  )
}

export default SettingUpTaxProfile
